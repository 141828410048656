import React from 'react';


export default class Logo extends React.Component {
    render() {
        return (
            <div  align="left">
                <img className="header-logo__image"
                     src="https://storage.googleapis.com/sabre-assets-prod/7.3.1/images/kramp-logo.svg"
                     alt="Kramp - It's that easy"/>
            </div>
        )
    }
}