import {useEffect, useState} from "react";
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';

export default function ClockComponent() {
    const [value, setValue] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div style={{marginLeft:"20px", marginTop:"10px", float:"left"}} >
            <Clock value={value} />
        </div>
    );
}

