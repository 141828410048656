import React from 'react';
import axios from 'axios';

export default class Monitor extends React.Component {
    state = {
        monitor: {"result":"NOT OK","services":{"authenticate":"OK","price":"OK","grossprice":"OK","atp":"NOT OK","itemavailability":"OK"}},
        services: <li></li>,
        lastCheck: null,
        checkLog: []
    }

    async getStatus() {
        try {
            await axios({
                url: `https://api.kramp.com/m2m-health`,
                method: 'GET',
                setTimeout: 50000
            })
                .then(res => {

                    if (res.statusCode === 503) {
                        this.setState({monitor: {result: "NOT OK"}});
                        console.log(`Error code 503`);
                    } else {
                        const monitor = res.data;
                        this.setState({monitor});
                        let servTemp = [];
                        Object.keys(monitor.services)
                            .forEach((key) => {
                                servTemp.push(<li key={key} className={(monitor.services[key] || "").replace(" ", "-")}
                                                  style={{opacity: "100%!"}}>{key}</li>)
                            });
                        this.setState({services: servTemp});
                        let logging = this.state.checkLog;
                        logging.push({"log": new Date()});
                        this.setState({checkLog: logging})
                    }
                    //console.log(monitor);
                })
                .catch(() => {
                    this.setState({monitor: {result: "NOT OK"}});
                    let logging = this.state.checkLog;
                    logging.push({"log": new Date()});
                    this.setState({checkLog: logging})
                    console.log(`Error code 503`);
                });
        }
        catch (e) {
            this.setState({monitor: {result: "NOT OK"}});
            console.log(`Axios failed, ${e.message()}`);
        }
    }

    componentDidMount() {
        this.getStatus()
            .then(() => {
                setInterval(async () => { await this.getStatus() } , 60000);
            })
    };

    render() {
        return (
            <div style={{
                margin: "0px",
                width: "90%",
                float: "right",
                opacity: "90%",
                minHeight: "15vh"
            }}>
                <ul className="nobull" style={{opacity: "100%", zIndex: -1, marginBottom: "0px"}}>
                    <li className={(this.state.monitor.result || "").replace(" ","-")}>API Status</li>
                </ul>
                <ul style={{marginBottom: "0px"}}>
                    {this.state.services}
                </ul>
            </div>
        )
    }
}