import './App.css';
import Monitor from './Monitor'
import ClockComponent from "./ClockComponent";
import Logo from "./Logo";


function App() {
    return (
        <div className="App" style={{margin: "0px", width: "100%"}}>
            <header className="App-header" >
                <div style={{ minHeight:"15vh"}}>
                    <div style={{float : "left", paddingBottom : "10px"}}>
                        <Logo/>
                        <ClockComponent/>
                    </div>
                    <Monitor/>
                </div>
            </header>
            <main className="App-main">
                &nbsp;
            </main>
            <footer className="App-footer">&nbsp;</footer>
        </div>
    );
}

export default App;
